import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import Header from '../Header';
import { useWebBrand } from '../WebBrand';
import LoginForm from './LoginForm';
import { LostPasswordForm } from '../Password';

import './LoginComponent.scss';

type LoginProps = {
	errorMessage?: string;
	performLogin: (userName: string, password: string, otp: string, branch: string) => void;
	supportUrl?: string;
};

const LoginComponent: React.FC<LoginProps> = ({
	performLogin,
	errorMessage,
	supportUrl,
}: LoginProps) => {
	const { webBrand } = useWebBrand();
	const [error, setError] = useState<string | undefined>(undefined);
	const [showLostPasswordForm, setShowLostPasswordForm] = useState(false);

	useEffect(() => {
		setError(errorMessage);
	}, [errorMessage]);

	const updateValue =
		(setter: Dispatch<SetStateAction<string>>) =>
		(e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			if (e.currentTarget.value) {
				setter(e.currentTarget.value);
			} else {
				setter('');
			}
		};

	const clickOnLostPassword = (): void => {
		setShowLostPasswordForm(true);
		setError('');
	};

	return (
		<>
			<Header />
			<Container className="mt-4">
				<Row>
					<Col md={{ span: 4, offset: 4 }}>
						<Card>
							<Card.Body>
								<Card.Title>
									<div className="brand-area">
										<img
											src={webBrand.iconUrl}
											alt={webBrand.name}
											className="brand-img"
										/>
										<div className="brand-title mt-4">{webBrand.name}</div>
										<div className="brand-sub-title mt-2">
											{webBrand.subtitle}
										</div>
									</div>
								</Card.Title>
								{showLostPasswordForm ? (
									<LostPasswordForm
										onClose={() => setShowLostPasswordForm(false)}
									/>
								) : (
									<>
										<LoginForm
											updateValue={updateValue}
											performLogin={performLogin}
											webBrand={webBrand}
											error={error}
											supportUrl={supportUrl}
										/>
										<div className="d-grid">
											<Button variant="link" onClick={clickOnLostPassword}>
												Forgot Password ?
											</Button>
										</div>
									</>
								)}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default LoginComponent;
