import React, { useContext } from 'react';
import LoginContext from './LoginContext';
import LoginComponent from './LoginComponent';
import NeedResetPassword from '../Password/NeedResetPassword';

type LoginRequiredProps = {
	children?: React.ReactNode;
};

const LoginRequired: React.FC<LoginRequiredProps> = ({ children }: LoginRequiredProps) => {
	const { isLoggedIn, performLogin, error, passwordResetData, supportUrl } =
		useContext(LoginContext);

	if (isLoggedIn) {
		if (passwordResetData?.needsPasswordReset) {
			return <NeedResetPassword passwordResetData={passwordResetData} />;
		}
		return children as React.ReactElement;
	}
	return (
		<LoginComponent performLogin={performLogin} errorMessage={error} supportUrl={supportUrl} />
	);
};

export default LoginRequired;
